import { AnalyticsLogger } from "@/logic/AnalyticsLogger";
import { use, useEffect } from "react";
import Head from "next/head";
import { PopupButton } from "@typeform/embed-react";
import dynamic from "next/dynamic";
import router from "next/router";
import Link from "next/link";


export default function Home() {
  useEffect(() => {
    AnalyticsLogger.getInstance().log("page_viewed_index");
  }, []);

  return (
    <>
      <Head>
        <title>RacePilot</title>
        <meta
          name="description"
          content="Simple and affordable live streaming and location tracking for your marathon race and other sport events."
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {/* <div className="navbar bg-base-100">
        <div className="flex-1">
          <a className="btn btn-ghost normal-case text-xl">RacePilot</a>
        </div>
        <div className="flex-none">
          <Link className="btn btn-primary" href="/studio">
            Sign up
          </Link>
        </div>
      </div> */}

      <div className="container mx-auto flex flex-col items-center">
        <div className="flex flex-col max-w-4xl items-center">
          <div className="max-w-md text-center px-2">
            <h1 className="text-5xl font-bold text-primary">
              Not accepting new sign ups.
            </h1>
            
          </div>

        </div>
      </div>
    </>
  );
}
