import { Profile } from "@/model/Profile";

var mixpanel = require('mixpanel-browser');
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { Crisp } from "crisp-sdk-web";

export class AnalyticsLogger {

    private readonly june = AnalyticsBrowser.load({
        writeKey: 'E8luNgxssdTGOZG9',
      });

    constructor() {
        mixpanel.init('7ac4c4cd22ca7f1a92ffd645ac18edd8');
    }

    public log(event: string, properties?: any) {
        console.log("Logging event: " + event + " with properties: " + JSON.stringify(properties));
        mixpanel.track(event, properties);
        this.june.track(event, properties);
    }

    public identify(profile: Profile) {
        mixpanel.identify(profile.id);
        mixpanel.people.set({ "email": profile.email });
        mixpanel.people.set({ "name": profile.name });
        mixpanel.people.set({ "has_studio_access": profile.hasStudioAccess });

        this.june.identify(profile.id, {
            email: profile.email,
            has_studio_access: profile.hasStudioAccess,
            name: profile.name
          });

          Crisp.user.setEmail(profile.email);
          if (profile.name) {
            Crisp.user.setNickname(profile.name);
          }
    }

    public reset() {
        mixpanel.reset();
    }

    private static instance: AnalyticsLogger;

    static getInstance(): AnalyticsLogger {
        if (!AnalyticsLogger.instance) {
            AnalyticsLogger.instance = new AnalyticsLogger();
        }

        return AnalyticsLogger.instance;
    }
}